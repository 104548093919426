@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

@font-face {
	font-family: 'Satoshi';
	src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
		url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}

.ant-form-item {
	margin-bottom: 1.25rem !important;
}

.ant-form-item-label {
	padding: 0 0 .25rem !important;
}

.ant-form-item-label label {
	font-family: "DM Sans" !important;
	font-size: 16px !important;
	font-weight: 500 !important;
	line-height: 24px !important;
}

.ant-select-selector {
	height: 52px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 0.5rem;
	border: 1px solid #9095A0 !important;
	box-shadow: 0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07);
	background-color: #fff;
}

.ant-select {
	height: 52px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 0.5rem;
	/* border: 1px solid #212121 !important; */
}

.select-borderless .ant-select-selector {
	height: 52px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 0.5rem;
	border: 1px solid #212121 !important;
}

.select-borderless .ant-select {
	height: 52px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 0.5rem;
	border: 1px solid #212121 !important;
}

.select-dotted .ant-select-selector {
	height: 2rem !important;
	width: 100% !important;
	border-radius: 1.875rem !important;
	border: 1px dashed var(--FMW-Primary, #0170BD) !important;
}

.select-dotted {
	height: 2rem !important;
	width: 100% !important;
	border-radius: 1.875rem !important;
}


.ant-form-item-extra {
	color: #565E6C !important; 
	font-family: "DM Sans" !important;
	font-size: 0.8125rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 140% !important;  
	margin-top: 0.5rem !important;
}



.ant-select .ant-select-arrow {
	color: #212121 !important;
	font-size: 16px !important;
}

.ant-input::placeholder {
	color: var(--text-primary, rgba(0, 0, 0, 0.87));
	opacity: 0.2;
	font-feature-settings: 'clig' off, 'liga' off;
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
	/* 150% */
	letter-spacing: 0.00938rem;
}

.input_borderless {
	border-radius: 0px;
	width: 100%;
	height: auto !important;
	border: none !important;
	font-size: 18px;
	background: transparent !important;
	padding: 0px !important;
}

.ant-input-affix-wrapper:hover {
	border: none !important;
}

.input_borderless:hover {
	border: none !important;
	box-shadow: none !important;
	outline: 0 !important;
}

.input_borderless:focus {
	border: none !important;
	box-shadow: none !important;
}

.input_borderless:focus-within {
	border: none !important;
	box-shadow: none !important;
}

.ant-input-affix-wrapper:focus {
	border: 1px solid rgba(56, 142, 60, 0.28) !important;
}

.ant-input-affix-wrapper-focused {
	border: 1px solid rgba(56, 142, 60, 0.28) !important;
}

/* .ant-input-affix-wrapper:hover {
	border: 1px solid rgba(56, 142, 60, 0.28) !important;
} */

.ant-tabs-nav-wrap {
	padding: 0px 0px !important;
	background-color: inherit !important;
}

.ant-tabs-nav-list {
	/* width: 30%; */
	display: flex;
	justify-content: space-between;
}

.ant-tabs-ink-bar {
	display: none !important;
}

.ant-breadcrumb-separator {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-breadcrumb a {
	color: var(--blue-grey, #545D69) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.3125rem !important;
	/* 150% */
}

.ant-breadcrumb span {
	color: var(--FMW-Primary, #0170BD) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.3125rem !important;
	text-transform: capitalize !important;
}

.ant-tabs-tab-active {
	display: flex !important;
	padding: 1rem 0.75rem !important;
	align-items: flex-start !important;
	gap: 0.5rem !important;
	border: 1px solid var(--Border-secondary, #ECECEC) !important;
	background: #FFF !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: var(--colors-brand-primary-alt-500, #388E3C) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

.ant-tabs-tab:hover>.ant-tabs-tab-btn {
	color: var(--colors-brand-primary-alt-500, #388E3C) !important;
}

.ant-tabs-tab:focus>.ant-tabs-tab-btn {
	color: var(--colors-brand-primary-alt-500, #388E3C) !important;
}

.ant-tabs-top>.ant-tabs-nav::before {
	border: none !important;
}

.underlined_tab .ant-tabs-tab-active {
	display: flex !important;
	padding: 0 !important;
	/* padding-bottom: 1.12rem !important; */
	align-items: flex-start !important;
	gap: 0.5rem !important;
	border: none !important;
	border-bottom: 0.3125rem solid #388E3C !important;
	background: #FFF !important;
}

.underlined_tab .ant-tabs-tab {
	padding: 0px !important;
}

.underlined_tab .ant-tabs-tab-btn {
	color: var(--Text-Black, #333) !important;
	font-family: "Lexend" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

.ant-table-wrapper {
	border-radius: 0.5rem !important;
	background: var(--White, #FFF) !important;
	box-shadow: 0px 2px 4px 0px rgba(34, 34, 34, 0.12) !important;
}

.ant-table-thead .ant-table-cell {
	background: #F4FCFF !important;
	color: var(--text-black, #222) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: normal !important;
	text-transform: capitalize !important;
}

.ant-table-cell {
	color: var(--Gray-900, #101828) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

td:first-child {
	color: var(--Gray-900, #101828) !important;
	font-family: "Lexend" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.5rem !important;
	/* 150% */
}

.ant-tag {
	display: flex;
	width: 8.4375rem;
	padding: 0.5rem .75rem;
	justify-content: center;
	align-items: center;
	gap: 0.625rem;
	border-radius: 1.25rem;
	/* border: 2px solid var(--Primary-Alert, #FFB201); */
}

.ant-spin-dot-item {
	background-color: #FFF !important;
}

.ant-modal-header {
	display: none !important;
}

.ant-modal-close-x {
	display: none !important;
}

.ant-modal-content {
	border-radius: 0.875rem !important;
	background: #FFF !important;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.ant-switch {
	width: 3rem !important;
	height: 1.6rem !important;
	border: 2px solid #fff !important;
}

.ant-switch-checked {
	background: #fff !important;
	border: 4px solid #388E3C !important;
}

.ant-switch-checked>.ant-switch-handle::before {
	background-color: #388E3C !important;
}

.ant-switch-checked .ant-switch-handle {
	top: 0.2px !important;
}

.ant-dropdown-menu .ant-dropdown-menu-item:hover {
	background-color: #FFF !important;
}

.ant-menu {
	background: transparent !important;
	display: flex !important;
	flex-direction: column !important;
	row-gap: 1.56rem !important;
	border-inline-end: 0px !important;
}

.ant-menu-item {
	padding-left: 3.19rem !important;
	height: 2.8125rem !important;
	border-radius: 0px !important;
}

.ant-menu-title-content {
	color: var(--Blue-grey, #7E93AE) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.3125rem !important;
	margin-inline-start: .75rem !important;
}

.ant-menu-item-icon>svg {
	fill: #7E93AE !important;
}

.ant-menu-item-icon {
	font-size: 1.5rem !important;
}

.ant-badge-count {
	background: #3F51F4 !important;
}

.ant-menu-item-selected {
	background: #E8FAFF !important;
	border-left: 0.4375rem solid #0170BD !important;
	border-radius: 0px !important;
}

.ant-menu-item-selected>.ant-menu-title-content {
	color: var(--FMW-Primary, #0873B9) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.3125rem !important;
	margin-inline-start: .75rem !important;
}

.ant-menu-item-selected .ant-menu-item-icon>path {
	fill: #0873B9 !important;
}

.ant-menu-submenu-arrow {
	display: none !important;
}

.ant-menu-submenu-title {
	border-top: .1rem solid rgba(207, 240, 252, 0.50) !important;
	border-bottom: .1rem solid rgba(207, 240, 252, 0.50) !important;
	height: 2.75rem !important;
}

.ant-menu-submenu-title .ant-menu-title-content {
	color: var(--FMW-Primary, #0170BD) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 1.3125rem !important;
	letter-spacing: 0.02188rem !important;
}

.ant-steps-item {
	position: relative;
	display: flex !important;
	justify-content: flex-start;
	vertical-align: top;
	align-items: center;
	margin-bottom: 28px;
}

.ant-steps-item-icon {
	width: 40px !important;
	height: 40px !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
}

.ant-steps-item-icon {
	background-color: #226cbf !important;
	border-color: #fff !important;
}

.ant-steps-icon {
	color: #fff !important;
	font-size: 15px !important;
}

.ant-steps-item-title {
	color: #6fa1f0 !important;
}

.ant-steps-item-active .ant-steps-item-icon {
	background-color: #fff !important;
	border-color: #226cbf !important;
}

.ant-steps-item-active .ant-steps-icon {
	color: #0071bc !important;
	font-size: 15px !important;
}

.ant-steps-item-active .ant-steps-item-title {
	color: #fff !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
	background-color: #6fa1f0 !important;
	border-color: #6fa1f0 !important;
}

.ant-steps-item-finish .ant-steps-item-title {
	color: #fff !important;
}

.ant-steps-item-tail::after {
	width: 2px !important;
	height: 45px !important;
	background-color: white !important;
	margin-left: 3px !important;
}

.ant-descriptions-title {
	color: var(--Gray-900, #101828) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 1.5rem !important;
}

.ant-descriptions-item-label {
	min-width: 15.5rem;
	color: var(--Black, #000) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.25rem !important;
}

.ant-descriptions-item-content {
	color: var(--Gray-500, #667085) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.25rem !important;
}

.ant-radio>span {
	color: var(--Gray-700, #344054) !important;
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 1.25rem !important;
	/* 142.857% */
}

.ant-radio-inner {
	background-color: #fff !important;
}

.ant-radio-inner::after {
	background-color: #0170BD !important;
}

.reject .ant-radio-inner::after {
	background-color: #C04544 !important;
}

.reject .ant-radio-inner {
	background-color: #fff !important;
	border: 1px solid #C04544 !important;
}

.input-file{
	color: var(--Field-text, #565E6C);
	font-family: "DM Sans";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.375rem; /* 157.143% */
}
.anticon.anticon-calendar{
	font-size: 1.5rem !important;
	color: #565E6C !important;
}
.ant-select .ant-select-selection-placeholder{ 
	color: var(--Field-text, #565E6C) !important; 
	font-family: "DM Sans" !important;
	font-size: 0.875rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.375rem !important; /* 157.143% */
} 
.ant-upload-drag {
	display: flex;
	width: 100%;
	height: 52px !important;
	padding: 0px 12px 0px 12px;
	align-items: center;
	border-radius: 8px !important;
	border: 1px solid #9095a0 !important;
	background: var(--White, #fff) !important;
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}


.ant-upload-text {
	color: var(--Field-text, #565e6c) !important;
	font-size: 14px !important;
	font-style: normal;
	font-weight: 400 !important;
	line-height: 22px !important; /* 157.143% */
}

::placeholder {
	color: var(--text-primary, rgba(0, 0, 0, 0.87)) !important;
	opacity: 0.5 !important;
	font-feature-settings: 'clig' off, 'liga' off !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.5rem !important; 
	letter-spacing: 0.00938rem !important;
}
 
.ant-select-selection-search-input {
	--tw-ring-color: none !important;
	--tw-ring-offset-shadow: none !important;
	--tw-ring-shadow: none !important;
	box-shadow: none !important;
	border-color: none !important;
	background: inherit !important;
	height: 3.5rem !important;
}

.ant-input-affix-wrapper{
	border-radius: 50px;
	width:22.5rem;
	height: 3.5rem !important; 
	box-shadow: 0 0 22px 0 rgba(26, 28, 40, 0.07) !important;
	border-radius: 0.25rem;
	border: 1px solid var(--FMW-Primary, #0170BD);
	font-size: 18px; 
	background: #fff !important;
}
.ant-breadcrumb-link{
	color: var(--blue-grey, #545D69) !important;
	font-family: "DM Sans" !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.3125rem !important;  
}
.ant-input-number-handler-wrap {
	display: none !important;
}
.ant-input-number-input-wrap{
	width: 100% !important;
	display: flex !important; 
	height: 100% !important;	
}