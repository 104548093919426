@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@font-face {
	font-family: 'Satoshi';
	src: url('./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2') format('woff2'),
		url('./assets/Fonts/WEB/fonts/Satoshi-Bold.woff') format('woff'),
		url('./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf') format('truetype');
}

:root {
	/* Primary Colors */
	/* --primary-navy-blue: #1E3A8A; */
	--primary-navy-blue: #3f51f4;
	--primary-emerald-green: #2f855a;
	--primary-slate-grey: #4a5568;

	/* Secondary Colors */
	--secondary-gold: #d69e2e;
	--secondary-light-grey: #a0aec0;
	--soft-grey: #e0e0e0;

	/* Neutral Colors */
	--neutral-white: #f8fdff;

	/* Accent Colors */
	--accent-royal-blue: #3b82f6;
	--accent-soft-green: #68d391;
	--accent-warm-yellow: #f6e05e;
}

/* Example Usage */
body {
	background-color: var(--neutral-white);
	color: var(--primary-slate-grey);
}

.react-tel-input .form-control {
	height: 100% !important;
	width: 100% !important;
	border: none !important;
	outline: none !important;
}

.header {
	background-color: var(--primary-navy-blue);
	color: var(--neutral-white);
}

.button-primary {
	background-color: var(--accent-royal-blue);
	color: var(--neutral-white);
}

.button-secondary {
	background-color: var(--secondary-gold);
	color: var(--neutral-white);
}

.success-message {
	color: var(--accent-soft-green);
}

.warning-message {
	color: var(--accent-warm-yellow);
}

.card {
	border: 1px solid var(--secondary-light-grey);
	background-color: #fff;
	border-radius: 0.83731rem;
	color: var(--primary-slate-grey);
}

.footer {
	background-color: var(--primary-emerald-green);
	color: var(--neutral-white);
}

.main-btn {
	width: 100%;
	display: flex;
	padding: 0.5625rem 1.25rem;
	justify-content: center;
	align-items: flex-start;
	align-self: stretch;
	border-radius: 624.9375rem;
	background: var(--accent-royal-blue);
	box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
	color: #fff;
	text-align: center;
	font-family: 'Lexend', sans-serif;
	font-size: 0.875rem !important;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5rem;
}

.home_card {
	width: 100%;
	padding: 16px;
	color: rgba(0, 0, 0, 0.87);
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
		rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
	overflow: hidden;
	display: flex;
	background-color: rgb(248, 253, 255);
	flex-direction: column;
}

.home_card_2 {
	background-color: rgb(255, 255, 255);
	color: rgba(0, 0, 0, 0.87);
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	border-radius: 4px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
		rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
	overflow: hidden;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.search {
	width: 22.5rem;
	height: 3.5rem;
	flex-shrink: 0;
	border-radius: 0.25rem;
	padding: 0.25rem 1.05rem;
	border: 1px solid var(--FMW-Primary, #0170bd);
}

.new_project_steps_card {
	display: flex;
	flex-direction: column;
	min-height: 40.5rem;
	height: auto;
	flex-shrink: 0;
	background: var(--FMW-Primary, #0170bd);
	box-shadow: 0px 0px 2px 0px rgba(23, 26, 31, 0.12),
		0px 0px 1px 0px rgba(23, 26, 31, 0.07);
	padding: 2.25rem 4rem;
}

.bg-custom-image {
	background-image: url('/src/assets/img/cardBg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.new_project_info_card {
	/* display: flex;
    flex-direction: column; */
	/* align-items: center; */

	width: 48.0625rem;
	max-width: 100%;
	min-height: 40.5rem;
	height: auto;
	/* flex-shrink: 0; */
	background: #f0f5ff;
	/* padding: 4.25rem 8.56rem; */
}

.info_desc_card {
	display: flex;
	flex-direction: column;
	min-width: 31rem;
	width: auto;
	height: auto;
	min-height: 16rem;
	padding: 0.75rem 1.625rem;
	align-items: flex-start;
	gap: 2.3125rem;
	flex-shrink: 0;
	border-radius: 0.5rem;
	background: #fefeff;
}

.info_desc_card_2 {
	display: flex;
	flex-direction: column;
	min-width: 31rem;
	width: auto;
	height: auto;
	padding: 0.75rem;
	align-items: flex-start;
	gap: 1.5rem;
	flex-shrink: 0;
	border-radius: 0.5rem;
	background: #fefeff;
}

.landing {
	/* background: url('./assets/svg/landing.svg'); */
	background: url('./assets/img/landing.png');
	background-repeat: no-repeat;
	background-position: center;
}

.tanks_img {
	background-repeat: no-repeat;
	border-bottom-left-radius: 35%;
	border-top-right-radius: 35%;
	object-fit: cover;
}

.need_help_card {
	display: flex;
	padding: 2rem 6rem 2rem 3rem;
	justify-content: space-between;
	align-items: center;
	align-self: stretch;
	border-radius: 1rem;
	background: #f3f8fc;
}

@media (max-width: 1024px) {
	.need_help_card {
		display: flex;
        background: #f3f8fc;
		padding: 2.9375rem 2.875rem 2.94rem 2.875rem;
		justify-content: center;
		align-items: center;
		align-self: stretch;
	}
}
