.input-phone {
	display: flex;
	width: 100%;
	height: 3.25rem;
	align-items: center;
	border-radius: 3px;
	border: 1px solid #9095a0;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input-phone:hover {
	border: 2px solid #388E3C47;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input-phone:focus {
	border: 2px solid #388E3C47;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.input {
	max-width: 100%;
	width: 100%;
	height: 3.25rem !important;
	align-items: center;
	border-radius: 0.5rem;
	border: 1px solid #9095A0;
	box-shadow: 0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07);
	background-color: #fff;
}

 
.input:hover {
	border: 1px solid #9095A0;

}

.input:focus {
	border: 1px solid #9095A0;
}

.input-textarea {
	max-width: 100%;
	width: 100%;
	align-items: center;
	border-radius: 6px !important;
	border: 1px solid #9095A0 !important;
}

.input-textarea:hover {
	border: 1px solid #9095A0;
}

.input-textarea:focus {
	border: 1px solid #9095A0;
}

.input-login {
	width: 100%;
	display: flex;
	padding: 0.625rem 0.8125rem;
	flex-direction: row;
	align-items: center;
	align-self: stretch;
	border-radius: 0.375rem;
	border: 1px solid #D4D4D8;
	background: #FFF;
}

.cstm-btn {
	min-width: 10.75rem;
	width: fit-content;
	padding: 0.625rem 1.25rem;
	display: flex;  
	justify-content: center; 
	align-items: center;
	/* align-self: stretch; */
	border-radius: 2.5rem;
	background: var(--Icon-active, #0170BD);
	color: var(--White, var(--White, #FFF));
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
	/* 150% */
}

.borderless-btn {
	width: 100%;
	display: flex;
	padding: 0.5625rem 1.25rem;
	justify-content: center;
	align-items: flex-start;
	/* align-self: stretch; */
	border-radius: 624.9375rem;
	background: #fff;
	border: none;
	color: #388E3C;
	text-align: center;
	font-family: "Lexend", sans-serif;
	font-size: 0.875rem !important;
	font-style: normal;
	font-weight: 600;
	line-height: 1.5rem;
}

.border-btn {
	min-width: 10.75rem;
	width: fit-content;
	display: flex;
	padding: 0.625rem 1.25rem;
	justify-content: center;
	align-items: center;
	/* align-self: stretch; */
	border-radius: 1.5625rem;
	border: 1px solid #0170BD;
	color: var(--FMW-Primary, #0170BD);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem;
}
.cancel-btn{
	color: var(--FMW-Primary, #0170BD);
	font-family: "DM Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 500;
	line-height: 1.5rem; /* 150% */
}
.save-btn{   
	border-radius: 0.375rem;
	background: var(--FMW-Primary, #0170BD);
	color: white;
	width: 4.8125rem;
	height: 2.25rem;
}
 


.side-bar-scroll::-webkit-scrollbar-track {
	background-color: transparent;
}

.side-bar-scroll::-webkit-scrollbar-thumb {
	border-radius: 20px;
	border: 6px solid transparent;
	background-clip: content-box;
}

.logo {
	width: 100%;
	height: 5.375rem;
	flex-shrink: 0;
	object-fit: contain;
}

.slanted-background {
	background-blend-mode: lighten;
	background: url('./assets/img/image1.png');
	background-size: 50% auto, 100% 100%;
	background-repeat: no-repeat;
	background-position: start;
	width: 100%;
	height: 100vh;
	padding-bottom: 3rem;
}

.login_title {
	font-family: "Satoshi";
	font-size: 3rem;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
}

@media (min-width: 1024px) { /* Adjust the breakpoint as needed */
	.gridx {
	  display: grid;
	  grid-template-columns: 29.9375rem auto; 
	}
	.new_project_steps_card, .new_project_info_card {
	  height: 100%;
	}
  }
  .bglbr8shd {
	border-radius: 8px;
	background: #f0f5ff;
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.signup-info {
	color: var(--White, #fff); 
	font-size: 1.375rem;
	font-style: normal;
	font-weight: 700;
	line-height: 120%; /* 26.4px */
}

.signup-info-text {
	color: #c6e1ff;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 18.2px */
}

.signup-start {
	color: #171a1f;
	text-align: center;
	font-size: 28px;
	font-style: normal;
	font-weight: 700;
	line-height: 44px; /* 157.143% */
}

.signup-start-text {
	color: #565e6c;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 22.4px */
}

.signup-begin {
	color: #171a1f;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 140%; /* 22.4px */
}

.signup-begin-text {
	color: #171a1f;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 22.4px */
}

.nxt-btn {
	display: flex;
	width: 172px;
	height: 52px;
	flex-direction: column;
	flex-shrink: 0;
	display: flex;
	padding: 10px 20px;
	justify-content: center;
	align-items: center;
	gap: 8px;
	flex: 1 0 0;
	border-radius: 25px;
	background: #226cbf;
	color: var(--White, var(--White, #fff));
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 24px; /* 150% */
	margin-top: 54.5px;
}

.already {
	color: #596e8f;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%; /* 19.6px */
}

.already-text {
	color: #226cbf;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
	text-decoration-line: underline;
}


.reg-phone {
	display: flex;
	width: 100%;
	height: 52px;
	/* padding: 0px 0px 0px 19px; */
	align-items: center;
	border-radius: 8px;
	border: 1px solid #9095a0;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.reg-phone:hover {
	border: 2px solid #285ff6;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.reg-phone:focus {
	border: 2px solid #285ff6;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.reg-input {
	/* display: flex; */
	width: 100%;
	height: 52px;
	/* padding: 0px 0px 0px 19px; */
	align-items: center;
	border-radius: 8px;
	border: 1px solid #9095a0;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.reg-input:hover {
	border: 2px solid #285ff6 !important;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}

.reg-input:focus {
	border: 2px solid #285ff6 !important;
	background: var(--White, #fff);
	box-shadow: 0px 0px 1px 0px rgba(23, 26, 31, 0.07),
		0px 0px 2px 0px rgba(23, 26, 31, 0.12);
}
